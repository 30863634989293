import styled from 'styled-components';
import FlexRow from '../FlexRow';
import { PageSectionEl, SectionWrapper } from '../PageSection/styles';
import * as colors from '../../styles/shared/colors';
import * as Text from '../../styles/shared/typography';
import { DashedDivider } from '../../styles/shared/layout';
import { respondTo } from '../../styles/shared/breakpoints';

export const EffTitle = styled(Text.H2)`
  border-bottom: 1px solid rgba(46, 55, 144, 1);
  font-weight: 800;
  margin-bottom: 16px;
  font-size: 30px;
  line-height: 1.2;

  ${respondTo.sm`
    font-size: 36px;
  `}
  padding-bottom: 0;
`;

export const EffSection = styled(PageSectionEl)`
  padding-top: 0;
  padding-bottom: 40px;
`;

export const EffWrapper = styled(SectionWrapper)`
`;

export const EffSubtitle = styled(Text.H4)`
  margin-bottom: 32px;
  color: rgba(63, 137, 200, 1);

  em {
    font-weight: 400;
  }
  sup {
    top: -0.4em;
    font-size: 14px;
    font-weight: 700;
  }

  ${respondTo.circleGraphLg`
    text-align: left;
  `}
`;

export const EffEyebrow = styled.div`
  margin-bottom: 16px;
  line-height: 1.3em;
  color: ${colors.brandBlueDark};
  font-weight: 700;
  margin-top: 24px;

  ${respondTo.circleGraphLg`
    margin-bottom: 4px;
  `}
`;

export const EffEyebrowAddendum = styled(Text.H2)`
  margin-bottom: 12px;
`;


// Circle Row
//----


export const CircleFlexRow = styled(FlexRow)`
  flex-direction: column;

  img {
    padding-right: 30px;
    margin-bottom: 24px;
    max-width: 446px;
    ${respondTo.sm`
    margin-bottom: 0;
  `}
  }

  
 
  
  ${respondTo.circleGraphLg`
    flex-direction: row;
    margin-bottom: 34px;
  `}
`;


export const CircleFlexRowImage = styled(FlexRow)`
  flex-direction: column;

  img {
    padding-right: 30px;
    margin-bottom: 24px;
    max-width: 446px;
    ${respondTo.sm`
    margin-bottom: 0;
  `}
  }
  &>div:nth-child(1) {
    margin-bottom: auto;
  }

  &>div:nth-child(2) {
    flex-grow: 1.2;
  }
 
  
  ${respondTo.circleGraphLg`
    flex-direction: row;
    margin-bottom: 34px;
  `}
`;



export const SideCard = styled.div`
  position: relative;
  margin-bottom: 24px;
  padding: ${({type}) => (type === 'tertiary') ? '0 0 0 36px' : '16px 16px 16px 36px'};
  border: ${({type}) =>
    (type === 'primary') ? `1px solid ${colors.cobalt}` :
    (type === 'secondary') ? `1px solid ${colors.silver}` :
    'none'
  };

  ${respondTo.circleGraphLg`
    margin-bottom: ${({type}) => (type === 'tertiary') ? '0' : '16px'};
  `}

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    width: 12px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${colors.cobalt};
    opacity: ${({type}) =>
      (type === 'secondary') ? '0.3' :
      (type === 'tertiary') ? '0.2' :
      '1'
    };
  }

  ${Text.H4} {
    margin-bottom: 0;
    color: ${colors.cobalt};
  }
  ${Text.Body} {
    margin-bottom: 0;
    line-height: 1.25em;
  }
`;


// Row 2
//----

export const Row2Left = styled.div`
  ${respondTo.sm`
    flex: 6 0 0;
    margin-right: 48px;
  `}
`;

export const Row2Right = styled.div`
  ${respondTo.sm`
  
    flex: 4 0 0;
    
  `}
`;

export const TertiaryPercentRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding-bottom: 2px;
`;

export const TertiaryPercent = styled.div`
  display: inline-block;
  flex: 0 0 auto;
  margin-right: 11px;
  font-size: 24px;
  line-height: 1em;
  font-weight: 700;
  color: ${colors.cobalt};
`;

export const TertiaryCopy = styled.div`
  display: inline-block;
  flex: 1 0 0;
  padding-left: 12px;
  font-size: 1rem;
  line-height: 1.25em;
  border-left: 1px solid ${colors.concrete};
`;

export const SideData = styled.div`
  position: relative;
  margin-bottom: 24px;
  padding: 3px 0 4px 36px;

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    width: 12px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${colors.weliregBlue};
    opacity: 0.4;
  }

  ${Text.H6} {
    margin: 0;
  }
  ${Text.Body} {
    margin: 0;
  }

  ${respondTo.sm`
    margin-bottom: 24px;
  `}
`;

export const JumpLinkRow = styled.div`
  margin-bottom: 32px;
`;


// NCCN
//----

export const NccnSection = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding-top: 32px;
  padding-bottom: 32px;
  background-color:  #E2EDF7;
  

  ${respondTo.circleGraphLg`
    padding-top: 36px;
    padding-bottom: 36px;
    text-align: left;
  `}
`;

export const NccnContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;

  ${respondTo.circleGraphLg`
    flex-direction: row;
    align-items: flex-start;
  `}
`;

export const NccnIcon = styled.div`
  display: inline-block;
  flex: 0 0 auto;
  height: 60px;
  width: auto;
  margin-bottom: 12px;

  img {
    display: inline-block;
    height: 100%;
  }

  ${respondTo.circleGraphLg`
    margin-right: 24px;
    margin-bottom: 0;
  `}
`;

export const NccnCopy = styled.div`
  display: inline-block;
  flex: 1;
  vertical-align: top;

  ${Text.H4} {
    margin: 0 0 4px;
    ${respondTo.sm`
      padding: 0 150px 0 0;
    `}
  }
  span {
    display: block;
    margin-bottom: 24px;
    color: ${colors.weliregBlue};
  }
  ${Text.Body} {
    margin: 0;
    ${respondTo.sm`
      padding: 0 100px 0 0;
    `}
  }

  ${respondTo.circleGraphLg`
    text-align: left;
  
    span {
      margin-bottom: 13px;
    }
  `}
`;

export const FootnoteWrap = styled.div`
  ${respondTo.sm`
    padding: 0 65px 0 0;
  `}  
`;

export const EffDivider = styled(DashedDivider)`
  margin: 32px 0 0;

  ${respondTo.circleGraphLg`
    margin: 48px 0 0;
  `}
`;