import styled from 'styled-components';
import { Reference } from '../../styles/shared/typography';

export const RefTitle = styled(Reference)`
  font-weight: bold;
`;

export const RefText = styled(Reference)`
  font-weight: 300;
  em {
    font-style: italic;
  }

  a {
    color: black;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const RefItalics = styled(RefText)`
  font-style: italic;
`;