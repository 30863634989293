import styled from 'styled-components';
import * as colors from '../../styles/shared/colors';
import ArrowDownRuby from '../../assets/icon-colors/arrow-down-ruby.svg';
import { respondTo } from '../../styles/shared/breakpoints';


export const JumpList = styled.nav`
  ${respondTo.sm`
    display: flex;
    column-gap: 24px;
  `}
`;

export const JumpLink = styled.a`
  display: block;
  margin-bottom: 8px;
  padding: 4px 0 4px 20px;
  text-decoration: none;
  line-height: 1.05em;
  color: ${colors.ruby};
  background-image: url(${ArrowDownRuby});
  background-size: 10px auto;
  background-repeat: no-repeat;
  background-position: 2px 5px;

  &:last-child {
    margin-bottom: 0;
  }

  @media(hover:hover) {
    &:hover {
      text-decoration: underline;
    }
  }

  ${respondTo.sm`
    display: inline-block;
    flex: 1;
    padding: 0 0 0 20px;
    line-height: 1.4em;
  `}
`;