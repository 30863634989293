import styled from 'styled-components';
import BulletList from '../BulletList';
import * as Text from '../../styles/shared/typography';
import * as colors from '../../styles/shared/colors';
import { respondTo } from '../../styles/shared/breakpoints';

import ImgRedArrow from '../../assets/images/img-red-arrow-right.png';

export const EfficacyBulletList = styled(BulletList)`
    div {
    color: ${colors.charcoal} !important;
    }

 
`;


export const StudyDesignWrapper = styled.div`
  margin-top: 24px;

  ${respondTo.sm`
    display: flex;
    align-items: flex-start;
    column-gap: 40px;
  `}
`;

export const StudyColumn = styled.div`
  position: relative;
  margin-bottom: 60px;

  .black-header {
    color: ${colors.charcoal};
  }

  &:after {
    content: "";
    position: absolute;
    top: calc(100% + 24px);
    left: calc(50% - 18px);
    display: inline-block;
    height: 12px;
    width: 36px;
    background-image: url("${ImgRedArrow}");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    transform: rotate(90deg);
  }

  &:last-child {
    margin-bottom: 0;

    &:after {
      display: none;
    }
  }

  ${respondTo.sm`
    display: inline-block;
    flex: 1 0 0;
    margin-bottom: 0;

    &:after {
      top: 120px;
      left: calc(100% - 1px);
      transform: rotate(0);
    }
  `}
`;

export const StudyCard = styled.div`
  margin-bottom: 16px;
  border-radius: 15px;
  border: 1px solid ${colors.cobalt};
  overflow: hidden;

`;

export const StudyCardHeader = styled.div`
  padding: 7px 12px 4px;
  text-align: center;
  font-size: 22px;
  line-height: 1.1em;
  font-weight: 700;
  color: ${colors.white};
  background-color: ${colors.cobalt};
`;

export const StudyCardBody = styled.div`
  display: flex;
  align-items: center;
  padding: 30px 16px;
  color: black !important;

  ${respondTo.sm`
    min-height: 225px;
    height: 100%;
    padding: 20px 16px;
  `}
`;

export const StudyCardList = styled(BulletList)`
  li {
    color: ${colors.cobalt};
    font-weight: 700;
  }
`;

export const StudyTreatmentGraphic = styled.div`
  width: 100%;
  text-align: center;

  img {
    width: 100%;
  }

  span.pill {
    display: inline-block;
    margin-right: 10px;
    padding: 9px 12px;
    font-size: 17px;
    font-weight: 700;
    color: ${colors.white};
    background-color: ${colors.cobalt};
    border-radius: 50%;

    &:last-child {
      margin: 0;
    }
  }
  ${Text.H6} {
    margin: 20px 0 0;
    color: ${colors.cobalt};
  }
`;

export const AssessmentH6 = styled(Text.H6)`
  margin: 0;
  color: ${colors.cobalt};
  text-align: center;
`;