import styled from 'styled-components';
import { respondTo } from '../../styles/shared/breakpoints';


export const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({mobileGap}) => mobileGap && `row-gap: ${mobileGap};`}
  ${({alignCenter}) => alignCenter && 'align-items: center;'}

  ${respondTo.sm`
    flex-direction: row;
    row-gap: 0;
    ${({gap}) => gap && `column-gap: ${gap};`}
  `}
`;

export const RowItem = styled.div`
  flex: 1 0 0;
  width: 100%;

  > img {
    display: block;
    width: 100%;
    height: auto;
  }
`;