import React from 'react';
import * as s from './styles';


const Reference = ({ data, className }) => {
  return (
    <section className={className}>

      <s.RefTitle>References:&nbsp;</s.RefTitle>

      {data.map((ref, i) => (
        <React.Fragment key={i}>
          <s.RefTitle>{i + 1}.</s.RefTitle>&nbsp;<s.RefText dangerouslySetInnerHTML={{__html: ref}} />&nbsp;
        </React.Fragment>),
      )}
    </section>
  );
};

export default Reference;