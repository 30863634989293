import React from 'react';
import PropTypes from 'prop-types';
import * as s from './styles';


const FlexRow = ({gap, mobileGap, noItemWrapper, alignCenter, children, className}) => {
  return (
    <s.Row
      gap={gap}
      mobileGap={mobileGap}
      alignCenter={alignCenter}
      className={className}
    >

      {noItemWrapper ?
        children
        :
        React.Children.map(children, (child, i) => (
          <s.RowItem key={i}>
            { child }
          </s.RowItem>
        ))
      }

    </s.Row>
  );
};


FlexRow.propTypes = {
  gap: PropTypes.string,
  noItemWrapper: PropTypes.bool,
  alignCenter: PropTypes.bool,
};


export default FlexRow;