let observer;
const  DEFAULT_OPTIONS = {
  threshold: 0.5,
};

export const MOBILE_SELECTOR_LABEL = 'On this page:';

export const WINDOW_SCROLL_TOP = 0;
export const INITIAL_SCROLL_TOP = 10;



const callback = (entries) => {
  const mainText = document.querySelector('.main-text-mobile');
  entries.forEach((entry) => {
      if (entry.isIntersecting){
        mainText.innerHTML = entry.target.dataset.label;
        // console.log('Visible',  entry.target.dataset.label);
      }
  });
};



export const observeIntersection = (ids) => {
  if (typeof window !== 'undefined' && 'IntersectionObserver' in window){
    observer = new IntersectionObserver(callback, DEFAULT_OPTIONS);
    Object.values(ids).forEach((id) => {
      const element = document.getElementById(id.id);
      if (!element){
        console.warn(`Element with id ${id.id} not found`);
        return;
      }
      element.setAttribute('data-label', id.label);
      observer.observe(element);
    });

  } else {
    console.warn('Intersection observer is not supported');
  }
};


export const unobserveIntersaction = () => {
  if (observer){
    observer.disconnect();
  }
};

