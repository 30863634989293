import * as React from 'react';
// Components
import PageSeo from '../../components/Seo/PageSeo';
import PageSection from '../../components/PageSection';
import EfficacySection from '../../components/EfficacySection';
import StudyDesign from '../../components/EfficacyStudyDesign';
import Footnotes from '../../components/Footnotes';
import * as Text from '../../styles/shared/typography';
import Reference from '../../components/Reference';
import * as s from '../../styles/pages/efficacy';
import styled from 'styled-components';
import { CalloutBanner } from '../../components';
import PageTitle from '../../components/PageTitle';
import { respondTo } from '../../styles/shared/breakpoints';
import {linkOffsetHash} from '../../util/jumpTo';
import { useEffect } from 'react';
// Content
import efficacyCopy from '../../copy/efficacy';

// SEO INFORMATION
const pageMeta = {
  title: 'LITESPARK-004 Efficacy Data for WELIREG (belzutifan) | VHL Disease',
  keywords: 'litespark-004',
  description:  'Health care professionals can review efficacy data and the study design from LITESPARK-004 for WELIREG™ (belzutifan) in patients with von Hippel-Lindau (VHL) disease–associated tumors.',
  schemaJsonLD: [
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/von-hippel-lindau/efficacy/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/von-hippel-lindau/efficacy/","@type":"Drug","proprietaryName":"WELIREG™","nonProprietaryName":"belzutifan","manufacturer":"Merck"}`,
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/von-hippel-lindau/efficacy/","@type":"MedicalTrial","name":"LITESPARK-004","description":"WELIREG demonstrated superior PFS vs everolimus in patients with advanced RCC following treatment with both anti⁠–PD⁠-⁠1⁠/⁠L1 and VEGF⁠-⁠TKI therapies","trialDesign":"WELIREG achieved an objective response across 3 VHL disease–associated tumor types"}`
  ]
}

const Spacer = styled.div`
  margin-bottom: 64px;
`;

const StudyDesignTitle = styled(Text.H2)`
  margin-bottom: 12px;
`

const StudyDesignSubtitle = styled(Text.H3)`
  font-weight: 400;
`;

export const EfficacyTitle = styled(Text.H2)`
  border-bottom: 1px solid rgba(46, 55, 144, 1);
  padding-bottom: 20px;
  font-weight: 800;
  font-size: 30px;
  line-height: 1.2;
  margin-bottom: 20px;

  ${respondTo.sm`
    font-size: 32px;
  `}
`;

export const EfficacyTitleSecondary = styled(Text.H2)`
  font-weight: 800;
  font-size: 30px;
  line-height: 1.2;
  margin-bottom: 20px;

  ${respondTo.sm`
    font-size: 32px;
  `}
`;

const anchorLinkItems = ['Renal Cell Carcinoma (RCC)',
'Central Nervous System (CNS) Hemangioblastomas',
'Pancreatic Neuroendocrine Tumors (pNET)', 'Study Design'];

const EfficacyPage = ({location}) => {
  const calloutBanner = {
    title: "Learn more about these attributes of WELIREG:",
    continualInvertedButtons: true,
    links: [
      {
        label: "Mechanism of Action",
        url: "/von-hippel-lindau/mechanism-of-action/",
      },
      {
        label: "Guidelines",
        url: "/von-hippel-lindau/guidelines/",
      },
      {
        label: "Safety",
        url: "/von-hippel-lindau/adverse-reaction-profile/",
      }
    ]
  };

  useEffect(() => {
    if(location.hash)
      linkOffsetHash(location.hash)

  }, []);

  return (
    <>
      <PageSeo pageMeta={pageMeta} >
       <link rel="canonical" href="https://www.welireghcp.com/von-hippel-lindau/efficacy/" />
      </PageSeo>


      <PageTitle anchorLinksData={anchorLinkItems}>
        { efficacyCopy.pageTitle }
      </PageTitle>

      <PageSection short btmFlat>
        <s.SubHeadWrap>
        <EfficacyTitle dangerouslySetInnerHTML={{__html: efficacyCopy.linkSection.title}} />
        </s.SubHeadWrap>
      </PageSection>

      {efficacyCopy.studies.map((study, i) => (
        <EfficacySection
          key={ study.title}
          jumpTarget={ efficacyCopy.linkSection.linksList[i].hrefId }
          useGrey={ (i % 2 === 0) }
          sectionTitle={ study.title }
          sectionTitleEyebrow={ study.superTitle }
          sectionTitleEyebrowAddendum={ study.superTitleAddendum}
          sectionSubtitle={ study.subTitle }
          circleImage={study.circleChart}
          circleImageAlt={study.circleImageAlt}
          primaryBoxTitle={ study.primaryCard.title }
          primaryBoxCopy={ study.primaryCard.copy }
          secondaryBoxTitle={ study.secondaryCard.title }
          secondaryBoxCopy={ study.secondaryCard.copy }
          tertiaryBoxTitle={ study.tertiaryCard.title }
          tertiaryBoxPercent={ study.tertiaryCard.percentCallout }
          tertiaryBoxPercentLabel={ study.tertiaryCard.percentLabel }
          sideDataTitle={ study.sideData.title }
          sideDataSubtitle={ study.sideData.subTitle }
          jumpToTitle={ study.jumpTo.title }
          jumpToLinkId={ study.jumpTo.linkToId }
          nccnTitle={ study.nccn?.title }
          nccnSubtitle={ study.nccn?.subTitle }
          nccnCopy={ study.nccn?.copy }
          footnoteItems={ study.footnotes }
        />
      ))}

      <div id="study-design-target" style={{position: 'relative', bottom: 100}}></div>
      <PageSection flat>
        <StudyDesignTitle id={ efficacyCopy.linkSection.linksList[3].hrefId }>
          { efficacyCopy.studyDesign.title }
        </StudyDesignTitle>
        <StudyDesignSubtitle >
          { efficacyCopy.studyDesign.subTitle }
        </StudyDesignSubtitle>
        <StudyDesign
          patientTitle={ efficacyCopy.studyDesign.patients.title }
          patientCardList={ efficacyCopy.studyDesign.patients.cardList }
          patientExclusionList={ efficacyCopy.studyDesign.patients.exclusionList }
          treatmentTitle={ efficacyCopy.studyDesign.treatment.title }
          treatmentPill={ efficacyCopy.studyDesign.treatment.pillCopy }
          treatmentPillSubtext={ efficacyCopy.studyDesign.treatment.pillSubcopy }
          treatmentStudyList={ efficacyCopy.studyDesign.patients.studyList }
          treatmentStudyListPart2={ efficacyCopy.studyDesign.patients.studyListPart2 }
          assessmentTitle={ efficacyCopy.studyDesign.assessment.title }
          assessmentCard={ efficacyCopy.studyDesign.assessment.card }
          assessmentSubLists={ efficacyCopy.studyDesign.assessment.endPointLists }
        />
      </PageSection>

      <PageSection btmFlat>
        <Footnotes items={ efficacyCopy.pageFootnotes }/>
      </PageSection>
      <Spacer />
      <PageSection flat>
        <Reference
          data={efficacyCopy.references}
        />
      </PageSection>

      <Spacer />

      <CalloutBanner {...calloutBanner} />
    </>
  );
};

export default EfficacyPage;
