import React from 'react';
import styled from 'styled-components';
import { WideWrapper } from '../../styles/shared/layout';
import { H1 } from '../../styles/shared/typography';
import { respondTo } from '../../styles/shared/breakpoints';
import {brandBlueDark, secondaryBrandRed, white} from '../../styles/shared/colors';
import AnchorLinks from '../AnchorLinks';

export const PageTitleBox = styled.div`
  width: 100%;
  margin: auto;
  margin-bottom: 0px;
  /* overflow: hidden; */
  position: relative;
  ${props => props.hasAnchorLinks === true ? '' :  `border-bottom: 2px solid ${secondaryBrandRed}; box-shadow: 0px 10px 20px 0px rgba(46, 55, 144, 0.15);`}
  `;

const BoxShadowWrapper = styled.div`
  box-shadow: 0px 10px 20px 0px rgba(46, 55, 144, 0.15);
  position: relative;
  margin-bottom: ${props => props.hasAnchorLinks ? '0px' : '20px'};
  border-bottom: 2px solid ${secondaryBrandRed};

  ${ respondTo.sm`
    margin-bottom: 20px;
  `}
`;

export const TitleWrapper = styled(WideWrapper)`
  display: flex;
  padding: 40px 20px;  
  background: ${white};
  
`;

export const PageTitleCopy = styled(H1)`
  color: ${brandBlueDark};
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;

 &.black {
color: black;
}
`;

export const TitleAnchorsMobile = styled(AnchorLinks)`
position: sticky;
top: 0;
background: white;
display: block;
z-index: 5;
box-shadow: rgba(46, 55, 144, 0.15) 0px 16px 20px 0px;
margin-bottom: ${props => props.hasAnchorLinks ? '0px' : '20px'};
border-top: 2px solid ${secondaryBrandRed};

    section {
      flex-flow:  wrap;
      flex-direction: row;
      margin-left: auto;
      margin-right: auto;
      gap: 10px;
      display: flex;
    }

    ${ respondTo.sm`
      display: none;
    `}
`;

export const TitleAnchorsDesktop = styled(AnchorLinks)`
  display: none;
  position: sticky;
  top: 0;
  z-index: 5;
  box-shadow: rgba(46, 55, 144, 0.15) 0px 16px 20px 0px;
  margin-bottom: ${props => props.hasAnchorLinks ? '0px' : '20px'};
  border-top: 2px solid ${secondaryBrandRed};
  background: white;

    section {
      flex-flow:  wrap;
      flex-direction: row;
      margin-left: auto;
      margin-right: auto;
      gap: 10px;
      display: flex;
    }

    ${ respondTo.sm`
    display: block

    `}
`;

const PageTitle = ({anchorLinksData, children, className }) => {
  return (
    <>
      <PageTitleBox hasAnchorLinks={anchorLinksData.length > 1}>
        <TitleWrapper>
          <PageTitleCopy className={className}>
            {children}
          </PageTitleCopy>
        </TitleWrapper>      
      </PageTitleBox>
        {
          anchorLinksData && anchorLinksData.length !== 0 && <TitleAnchorsMobile  id="anchor-link-bar-mobile" linkItems={anchorLinksData} isMobile={true}/>
        }
        {
          anchorLinksData && anchorLinksData.length !== 0 && <TitleAnchorsDesktop id="anchor-link-bar" linkItems={anchorLinksData}/>
        }
    </>
  );
};

PageTitle.defaultProps = {
  anchorLinksData: false,
};

export default PageTitle;
