import React from 'react';
import { Ul, ListTitle } from '../../styles/shared/typography';


const BulletList = ({listTitle, listItems, className}) => {
  return (
    <div className={className}>
      {listTitle &&
        <ListTitle
          dangerouslySetInnerHTML={{__html: listTitle}}
        />
      }

      <Ul >
        {listItems.map((listItem, i) => (
          <li key={i}>
            <span
              dangerouslySetInnerHTML={{__html: listItem[0]}}
            />

            {listItem[1] &&
              <ul>
                {listItem[1].map((subListItem, j) => (
                  <li
                    key={j}
                    dangerouslySetInnerHTML={{__html: subListItem}}
                  />
                ))}
              </ul>
            }
          </li>
        ))}
      </Ul>
    </div>
  );
};


export default BulletList;