
import Pie1 from '../assets/images/pie-1.svg';
import Pie2 from '../assets/images/pie-2.svg';
import Pie3 from '../assets/images/pie-3.svg';

const linksList = [
  {
    title: 'Renal Cell Carcinoma (RCC)',
    hrefId: 'Renal-Cell-Carcinoma-RCC',
  },
  {
    title: 'Central Nervous System (CNS) Hemangioblastomas',
    hrefId: 'Central-Nervous-System-CNS-Hemangioblastomas',
  },
  {
    title: 'Pancreatic Neuroendocrine Tumors (pNET)',
    hrefId: 'Pancreatic-Neuroendocrine-Tumors-pNET',
  },
  {
    title: 'Study Design',
    hrefId: 'Study-Design',
  },
];

const studyJumpTo = {
  title: 'See study design',
  linkToId: linksList[3].hrefId,
};


const efficacyCopy = {
  pageTitle: 'Efficacy Data for WELIREG™ (belzutifan)',

  linkSection: {
    title: 'Clinical trial results from <span class="no-wrap">LITESPARK-004</span>',
    subTitle: '',
    linksTitle: 'On this page',
    linksList,
  },

  studies: [
    {
      title: 'WELIREG achieved an objective response across 3 VHL <span class="no-wrap">disease–associated</span> tumor types',
      superTitle: 'In LITESPARK-004, an open-label clinical trial for patients with VHL disease–associated RCC (N=61),',
      superTitleAddendum: 'WELIREG reduced tumor size in VHL disease–associated RCC',
      subTitle: 'NEARLY HALF OF PATIENTS HAD AN OBJECTIVE RESPONSE',
      circleChart: Pie1,
      circleImageAlt: 'In LITESPARK-004, an Open-Label Clinical Trial, WELIREG™ (belzutifan) Reduced Tumor Size in VHL Disease–associated RCC',
      primaryCard: {
        title: 'ORR per RECIST v1.1:',
        copy: 'Complete response defined as disappearance of all target and non-target lesions. Any pathological lymph nodes (whether target or non-target) must have reduction in short axis to <10&nbsp;mm. Partial response defined as ≥30% decrease in the sum of the longest diameters of target lesions compared with baseline.<sup>1</sup>',
        
      },
      secondaryCard: {
        title: 'Median DOR was not reached',
        copy: 'Median DOR could not be estimated<sup>b</sup> since the majority of patients who responded to treatment maintained their response (did not experience disease progression per RECIST v1.1) at the time of data cutoff.<sup>2</sup>',
      },
      tertiaryCard: {
        title: 'Median DOR was not reached, with ongoing responses ranging from 2.8 to 22 months',
        percentCallout: '56',
        percentLabel: 'of patients who responded (n=17/30) maintained a response that lasted ≥12 months',
      },
      sideData: {
        title: 'TTR as early as 2.7 months',
        subTitle: '(median: 8 months; range: 2.7 to 19 months)',
      },
      jumpTo: studyJumpTo,
      nccn: {
        title: 'NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Kidney Cancer (confirmed hereditary VHL disease–associated RCC)',
        //subTitle: '',
        copy: 'Belzutifan (WELIREG) is the <strong>only preferred systemic therapy option (category 2A)</strong> for patients with VHL disease–associated RCC not requiring immediate surgery.<sup>3</sup>',
      },
      footnotes: [
        {
          ref: 'a',
          copy: 'All patients with a response were followed for a minimum of 18 months from the start of treatment.',
        },
        {
          ref: 'b',
          copy: 'By Kaplan-Meier method.',
          marginBottom: true,
        },
        {
          copy: 'Category 2A = Based upon lower-level evidence, there is uniform National Comprehensive Cancer Network® (NCCN®) consensus that the intervention is appropriate.',
        },
        {
          copy: 'NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.',
          marginBottom: true,
        },
        {
          copy: 'CI = confidence interval; DOR = duration of response; NCCN = National Comprehensive Cancer Network; ORR = objective response rate; RCC = renal cell carcinoma; RECIST v1.1 = Response Evaluation Criteria In Solid Tumors v1.1; TTR = time to response; VHL = von Hippel-Lindau.',
        },
      ],
    }, // end RCC

    {
      title: 'Patient subgroup: WELIREG reduced tumor size in VHL <span class="no-wrap">disease–associated</span> CNS hemangioblastomas',
      subTitle: 'NEARLY TWO-THIRDS OF PATIENTS HAD AN OBJECTIVE RESPONSE ',
      circleChart: Pie2,
      circleImageAlt: 'Patient Subgroup: WELIREG™ (belzutifan) Reduced Tumor Size in VHL Disease–associated CNS Hemangioblastomas',
      primaryCard: {
        title: 'ORR per RECIST v1.1:',
        copy: 'Complete response defined as disappearance of all target and non-target lesions. Any pathological lymph nodes (whether target or non-target) must have reduction in short axis to <10&nbsp;mm. Partial response defined as ≥30% decrease in the sum of the longest diameters of target lesions compared with baseline.<sup>1</sup>',
      },
      secondaryCard: {
        title: 'Median DOR was not reached',
        copy: 'Median DOR could not be estimated<sup>b</sup> since the majority of patients who responded to treatment maintained their response (did not experience disease progression per RECIST v1.1) at the time of data cutoff.<sup>2</sup>',
      },
      tertiaryCard: {
        title: 'Median DOR was not reached, with ongoing responses ranging from 3.7 to 22 months',
        percentCallout: '73',
        percentLabel: 'of patients who responded (n=11/15) maintained a response that lasted ≥12 months',
      },
      sideData: {
        title: 'TTR as early as 2.5 months',
        subTitle: '(median: 3.1 months; range: 2.5 to 11 months)',
        percentLabel: 'of patients who responded (n=11/15) maintained a response that lasted ≥12 months',
      },
      jumpTo: studyJumpTo,
      nccn: {
        title: 'NCCN Guidelines® for CNS Cancers (VHL disease–associated CNS hemangioblastomas)',
        //subTitle: '',
        copy: 'Belzutifan (WELIREG) is the <strong>only systemic therapy option identified as useful in certain circumstances (category 2A)</strong> for patients with VHL disease–associated CNS hemangioblastomas not requiring immediate surgery.<sup>4</sup>',
      },
      footnotes: [
        {
          ref: 'a',
          copy: 'Number of patients with measurable solid lesions, based on IRC assessment.',
        },
        {
          ref: 'b',
          copy: 'By Kaplan-Meier method.',
          marginBottom: true,
        },
        {
          copy: 'Category 2A = Based upon lower-level evidence, there is uniform NCCN consensus that the intervention is appropriate.',
        },
        {
          copy: 'NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.',
          marginBottom: true,
        },
        {
            copy: ' CI = confidence interval; CR = complete response; CNS = central nervous system; DOR = duration of response; IRC = independent review committee; NCCN = National Comprehensive Cancer Network; NCCN Guidelines = NCCN Clinical Practice in Oncology; ORR = objective response rate; PR = partial response; RECIST v1.1 = Response Evaluation Criteria In Solid Tumors v1.1; TTR = time to response; VHL = von Hippel-Lindau.',
            },
      ],
    }, // end CNS

    {
      title: 'Patient subgroup: WELIREG reduced tumor size in VHL <span class="no-wrap">disease–associated</span> pNET',
      subTitle: 'THE MAJORITY OF PATIENTS HAD AN OBJECTIVE RESPONSE ',
      circleChart: Pie3,
      circleImageAlt: 'Patient Subgroup: WELIREG™ (belzutifan) Reduced Tumor Size in VHL Disease–associated pNET',
      primaryCard: {
        title: 'ORR per RECIST v1.1:',
        copy: 'Complete response defined as disappearance of all target and non-target lesions. Any pathological lymph nodes (whether target or non-target) must have reduction in short axis to <10&nbsp;mm. Partial response defined as ≥30% decrease in the sum of the longest diameters of target lesions compared with baseline.<sup>1</sup>',
      },
      secondaryCard: {
        title: 'Median DOR was not reached',
        copy: 'Median DOR could not be estimated<sup>b</sup> since the majority of patients who responded to treatment maintained their response (did not experience disease progression per RECIST v1.1) at the time of data cutoff.<sup>2</sup>',
      },
      tertiaryCard: {
        title: 'Median DOR was not reached, with ongoing responses ranging from 11 to 19 months',
        percentCallout: '50',
        percentLabel: 'of patients who responded (n=5/10) maintained a response that lasted ≥12 months',
      },
      sideData: {
        title: 'TTR as early as 2.7 months',
        subTitle: '(median: 8.1 months; range: 2.7 to 11 months)',
      },
      jumpTo: studyJumpTo,
      footnotes: [
        {
          ref: 'a',
          copy: 'Number of patients with measurable solid lesions, based on IRC assessment.',
        },
        {
          ref: 'b',
          copy: 'By Kaplan-Meier method.',
          marginBottom: true,
        },
        {
          copy: 'CI = confidence interval; CR = complete response; DOR = duration of response; IRC = independent review committee; ORR = overall response rate; pNET = pancreatic neuroendocrine tumors; PR = partial response; RECIST v1.1 = Response Evaluation Criteria In Solid Tumors v1.1; TTR = time to response; VHL = von Hippel-Lindau.',
        },
      ],
    }, // end pNET

  ], // end studies

  studyDesign: {
    title: 'Study design',
    subTitle: 'WELIREG was evaluated in LITESPARK-004, an open-label clinical trial',
    patients: {
      title: 'Patients',
      cardList: [
        [
          'VHL-associated RCC (N=61)',
          [
            '≥1 measurable solid tumor localized to the kidney<sup>a</sup>',
          ],
        ],
        [
          'Enrolled patients had other VHL-associated tumors<sup>a,b</sup>:',
          [
            'CNS hemangioblastomas (n=24)',
            'pNET (n=12)',
          ],
        ],
      ],
      exclusionList: {
        title: 'Exclusion criteria',
        list: [
          ['Any evidence of metastatic disease'],
        ],
      },
      studyList: {
        title: 'Study population characteristics',
        list: [
          ['Median age: 41 years (range 19 to 66 years)'],
          ['3.3% age 65 years or older '],
          ['53% male'],
          ['90% White, 3.3% Black or African-American, 1.6% Asian, and 1.6% Native Hawaiian or other Pacific Islander'],
        ],
      },
      studyListPart2: {
        title: 'Study population characteristics',
        list: [
          ['Eastern Cooperative Oncology Group performance status (ECOG PS) of 0, 82%; ECOG PS of 1, 16%; and ECOG PS of 2, 1.6%'],
          ['Prior RCC surgical procedures: 77%'],
          ['Median time from initial radiographic diagnosis of VHL-associated RCC tumors that led to enrollment to the time of treatment with WELIREG was 17.9 months (range: 2.8 to 96.7 months)'],
        ],
      },
    },
    treatment: {
      title: 'Treatment',
      pillCopy: '40 mg',
      pillSubcopy: 'WELIREG 120 mg once daily',
    },
    assessment: {
      title: 'Assessment',
      card: 'Treatment was continued until progression of disease or unacceptable toxicity',
      endPointLists: [
        {
          title: 'Major efficacy end point for RCC',
          list: [
            [
              'Overall response rate (ORR)',
              [
                'Measured by radiology assessment using RECIST v1.1 as assessed by IRC<sup>c</sup>',
              ],
            ],
          ],
        },
        {
          title: 'Additional efficacy end points for RCC',
          list: [
            ['Duration of response (DOR)'],
            ['Time to response (TTR)'],
          ],
        },
        {
          title: 'Additional efficacy end points for CNS hemangioblastomas and pNET<sup>d<sup>',
          list: [
            ['ORR'],
            ['DOR'],
            ['TTR'],
          ],
        },
      ],
    },
  },

  pageFootnotes: [
    {
      ref: 'a',
      copy: 'As defined by RECIST v1.1.',
    },
    {
      ref: 'b',
      copy: 'Based on central independent review committee (IRC).',
    },
    {
      ref: 'c',
      copy: 'CNS hemangioblastomas and pNET in these patients were diagnosed based on the presence of at least 1 measurable solid tumor in the brain/spine or pancreas, respectively, as defined by RECIST v1.1 and identified by IRC.',
    },
    {
      ref: 'd',
      copy: 'RECIST v1.1 response defined as ≥30% decrease in the sum of diameters of target lesions, taking as reference the baseline sum diameters.<sup>1</sup>',
    },
    {
      ref: 'e',
      copy: 'Assessed by IRC using RECIST v1.1.',
      marginBottom: true,
    },
    {
      copy: 'VHL = von Hippel-Lindau; RCC = renal cell carcinoma; CNS = central nervous system; ECOG PS = Eastern Cooperative Oncology Group performance status; pNET = pancreatic neuroendocrine tumors; RECIST v1.1 = Response Evaluation Criteria In Solid Tumors v1.1.',
    },
  ],

  bottomBtn: {
    label: 'See Adverse Reaction Profile',
    to: '/von-hippel-lindau/adverse-reaction-profile',
  },

  references: [
    'Eisenhauer EA, Therasse P, Bogaerts J, et al. New response evaluation criteria in solid tumours: revised RECIST guideline (version 1.1). <em>Eur J Cancer.</em> 2009;45(2):228–247.',
    'Delgado A, Guddati AK. Clinical endpoints in oncology - a primer. <em>Am J Cancer Res.</em> 2021;11(4):1121–1131.',
    'Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Kidney Cancer V.2.2024. © National Comprehensive Cancer Network, Inc. 2024. All rights reserved. Accessed January 5, 2024. To view the most recent and complete version of the guideline, go to NCCN.org.',
    'Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Central Nervous System Cancers V.1.2023. © National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed April 3, 2023. To view the most recent and complete version of the guideline, go to NCCN.org.',
  ],

  buttonBlade: {
    title: 'Learn more about these attributes of WELIREG:',
    buttonLabelLeft: 'Mechanism of Action',
    buttonHrefLeft: '/von-hippel-lindau/mechanism-of-action',
    buttonLabelMid: 'Guidelines',
    buttonHrefMid: '/von-hippel-lindau/guidelines',
    buttonLabelRight: 'Adverse Reaction Profile',
    buttonHrefRight: '/von-hippel-lindau/adverse-reaction-profile',
  },
};


export default efficacyCopy;
