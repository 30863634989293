import React from 'react';

import Footnotes from '../Footnotes';
import { JumpLink } from '../JumpLinkList/styles';
import * as Text from '../../styles/shared/typography';
import * as s from './styles';

import {onLinkClickOffset} from '../../util/jumpTo';
import NccnIcon from '../../assets/images/icon-guidelines-page.png';

import styled from 'styled-components';
import PageSeparator from '../PageSeparator';


const EffTitle = styled(Text.H3)`
  font-weight: 500;
  margin-bottom: 12px;
`;

const EfficacySection = ({
  useGrey,
  jumpTarget,
  sectionTitle,
  sectionTitleEyebrow,
  sectionTitleEyebrowAddendum,
  sectionSubtitle,
  circlePercent,
  circleSubHead,
  circleSideCopy,
  circleOrrSup,
  primaryBoxTitle,
  primaryBoxCopy,
  secondaryBoxTitle,
  secondaryBoxCopy,
  tertiaryBoxTitle,
  tertiaryBoxPercent,
  tertiaryBoxPercentLabel,
  sideDataTitle,
  sideDataSubtitle,
  jumpToTitle,
  jumpToLinkId,
  nccnTitle,
  nccnSubtitle,
  nccnCopy,
  footnoteItems,
  showIcon,
  circleImage,
  circleImageAlt,
}) => {
  return (
    <s.EffSection >
      <s.EffWrapper>
        <EffTitle
          id={jumpTarget}
          dangerouslySetInnerHTML={{__html: sectionTitle}}
        />
        {sectionTitleEyebrow &&
          <s.EffEyebrow>
            { sectionTitleEyebrow }
          </s.EffEyebrow>
        }
        {sectionTitleEyebrowAddendum &&
          <s.EffEyebrowAddendum>
            {sectionTitleEyebrowAddendum}
          </s.EffEyebrowAddendum>
        }

        <s.EffSubtitle
          dangerouslySetInnerHTML={{__html: sectionSubtitle}}
        />

        <s.CircleFlexRowImage alignCenter>
          <img src={circleImage} alt={circleImageAlt}/>
          {/* <CircleChart
            greyBg={useGrey}
            percentage={circlePercent}
            subHeading={<>All responses were partia <br/> responses; complete <br/> response, 0%</>}
            sideCopy={circleSideCopy}
            orrSup={circleOrrSup}
          /> */}
          <div>
            <s.SideCard type="primary">
              <Text.H4>
                { primaryBoxTitle }
              </Text.H4>
              <Text.Body
                dangerouslySetInnerHTML={{__html: primaryBoxCopy}}
              />
            </s.SideCard>

            <s.SideCard type="secondary">
              <Text.H4>
                { secondaryBoxTitle }
              </Text.H4>
              <Text.Body
                dangerouslySetInnerHTML={{__html: secondaryBoxCopy}}
              />
            </s.SideCard>
          </div>
        </s.CircleFlexRowImage>

        <s.CircleFlexRow noItemWrapper>
          <s.Row2Left>
            <s.SideCard type="tertiary">
              <Text.H4>
                { tertiaryBoxTitle }
              </Text.H4>
              <s.TertiaryPercentRow>
                <s.TertiaryPercent>
                  { tertiaryBoxPercent }%
                </s.TertiaryPercent>
                <s.TertiaryCopy
                  dangerouslySetInnerHTML={{__html: tertiaryBoxPercentLabel}}
                />
              </s.TertiaryPercentRow>
            </s.SideCard>
          </s.Row2Left>

          <s.Row2Right>
            <s.SideData>
              <Text.H6>{sideDataTitle}</Text.H6>
              <Text.Body>
                {sideDataSubtitle}
              </Text.Body>
            </s.SideData>
          </s.Row2Right>
        </s.CircleFlexRow>

        <s.JumpLinkRow>
          <JumpLink href={ '#' + jumpToLinkId } onClick={onLinkClickOffset}>
            { jumpToTitle }
          </JumpLink>
        </s.JumpLinkRow>
      </s.EffWrapper>

      {nccnTitle &&
        <s.NccnSection>
          <s.EffWrapper>

            <s.NccnContent>
              {
                showIcon && showIcon ? <s.NccnIcon><img src={NccnIcon} alt="document icon" /></s.NccnIcon> :   null
              }
              <s.NccnCopy>
                <Text.H4>
                  { nccnTitle }
                </Text.H4>
                <span>{ nccnSubtitle }</span>
                <Text.Body
                  dangerouslySetInnerHTML={{__html: nccnCopy}}
                />
              </s.NccnCopy>
            </s.NccnContent>

          </s.EffWrapper>
        </s.NccnSection>
      }

      <s.EffWrapper $bottom>
        <s.FootnoteWrap>
          <Footnotes items={footnoteItems} />
          </s.FootnoteWrap>
        {/* <s.EffDivider /> */}

          <PageSeparator topPadding='40px' bottomPadding='0px' bottomBorder={true}/>

      </s.EffWrapper>
    </s.EffSection>
  );
};


export default EfficacySection;