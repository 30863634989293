import { breakpoints } from "../styles/shared/breakpoints";

const PAGE_MAIN_SELECTOR = "[class^='styles__PageMain-']";
const ANCHOR_LINKS_SELECTOR = "#anchor-link-bar";
const ANCHOR_LINKS_MOBILE_SELECTOR = "#anchor-link-bar-mobile";

const LINK_OFFSET_DESKTOP = 32;
const MOBILE_OFFSET = 16;
const ZERO_OFFSET = 0;
const BASIC_OFFSET = 50;
const OFFSET_DELAY = 100;

const getAnchorElements = () =>{
  let elements = {};

  elements.pageMain = document.querySelector(PAGE_MAIN_SELECTOR);
  elements.AnchorLinksRoot = document.querySelector(ANCHOR_LINKS_SELECTOR);
  elements.AnchorLinksRootMobile = document.querySelector(ANCHOR_LINKS_MOBILE_SELECTOR);
  return elements;
}

const getOffsetValues = (isMobile, AnchorLinksRootMobile, AnchorLinksRoot) =>{
  let offsetValues = {};

  offsetValues.offset = isMobile ? AnchorLinksRootMobile : LINK_OFFSET_DESKTOP + AnchorLinksRoot;
  offsetValues.offsetParent = window;
  offsetValues.offsetParentTop = isMobile ? MOBILE_OFFSET : ZERO_OFFSET;
  return offsetValues;
}

const scrollTarget = (offsetParent, topValue) => {
  offsetParent.scroll({
    top: topValue,
    behavior: 'smooth',
  });
}

const getIsMobile = (innerWidth) => {
  return innerWidth < breakpoints.sm;
}

export const onLinkClick = (e) => {

  e.preventDefault();

  const hashTarget = document.querySelector(e.target.hash);
  if (hashTarget) {
    window.scrollTo({
      top: hashTarget.offsetTop - BASIC_OFFSET,
      behavior: 'smooth',
    });
  }
};



export const onLinkClickOffset = (e) => {

  const isMobile = getIsMobile(window.innerWidth);

  e.preventDefault();

  const anchorTarget = e.target.closest('a');

  if (!anchorTarget) {
    return;
  }

  const hashTarget = document.querySelector(anchorTarget.hash);

  const {pageMain, AnchorLinksRootMobile, AnchorLinksRoot} = getAnchorElements();
  const {offsetParent, offsetParentTop, offset} = getOffsetValues(isMobile, AnchorLinksRootMobile.offsetHeight, AnchorLinksRoot.offsetHeight);

  if (!hashTarget || !pageMain) {
    return;
  }

  scrollTarget(offsetParent, hashTarget.offsetTop - offsetParentTop - offset);  
};

export const linkOffsetHash = async(hash) => {

  const isMobile = getIsMobile(window.innerWidth);

  const hashTarget = document.querySelector(hash);

  const {pageMain, AnchorLinksRootMobile, AnchorLinksRoot} = getAnchorElements();
  const {offsetParent, offsetParentTop, offset} = getOffsetValues(isMobile, AnchorLinksRootMobile.offsetHeight, AnchorLinksRoot.offsetHeight);

  if (!hashTarget || !pageMain) {
    return;
  }

  const targetOffsetTop = await getTargetOffsetTop(hashTarget);

  scrollTarget(offsetParent, targetOffsetTop - offsetParentTop - offset);
};

const getTargetOffsetTop = (hashTarget) => {
    return new Promise ((resolve, reject) => {
        if (hashTarget){
          setTimeout(() => {
            const target = hashTarget.offsetTop;
            resolve(target);
          }, OFFSET_DELAY);
        } else {
          console.error('Element not found');
        }
    });
};
