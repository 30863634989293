import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as colors from '../../styles/shared/colors';
import DownCarat from '../../assets/icon-colors/icon-carat-down.svg';
import UpCarat from '../../assets/icon-colors/icon-carat-up.svg';
import DownArrow from '../../assets/icon-colors/icon-button-arrow-down.svg';
import { respondTo } from '../../styles/shared/breakpoints';
import { onLinkClickOffset } from '../../util/jumpTo';
import {observeIntersection, unobserveIntersaction, MOBILE_SELECTOR_LABEL, WINDOW_SCROLL_TOP, INITIAL_SCROLL_TOP} from '../../util/intersectionObserver';

const Root = styled.div`
  border: 1px solid ${colors.brandBlueDark};

  ${respondTo.sm`
   border: none;
  `}
`;

const MainDisplay = styled.section`
  padding: 10px 13px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;


  ${respondTo.sm`
   padding: 15px 20px;
   justify-content: center;
   max-width: 972px;
  `}
`;

const MainText = styled.div`
  font-weight: 700;
  line-height: 20px;
  color: ${colors.bodyCopy};
  width: calc(100% - 30px);


  ${respondTo.sm`
   margin-right: 30px;
   max-width: 55vw;
   width: fit-content;
  `}
`;

const DesktopLinkItem = styled.a`
  display: none;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  

  ${respondTo.sm`
   display: flex;
   margin-right: 30px;
  `}
`;

const DesktopLinkItemText = styled.div`
  font-weight: 600;
  line-height: 20px;
  color: ${colors.brandBlueDark};
  cursor: pointer;
  
`;

const MainImageContainer = styled.img`
  cursor: pointer;

  ${respondTo.sm`
   display: none;
  `}
`;

const DrawerContainer = styled.div`
  display: block;
  ${respondTo.sm`
    display: none;
  `}
`;

const DrawerItem = styled.a`
  display: flex;
  padding: 10px 0px 10px 10px;
  cursor: pointer;
  text-decoration: none;
  

  ${respondTo.sm`
    display: inline;
  `}

`;

const DrawerImageContainer = styled.img`
  margin-right: 8px;
`;

const DrawerTextContainer = styled.div`
  font-weight: 600;
  line-height: 20px;
  color: ${colors.brandBlueDark};
`;

const REGEX_BLANK_SPACE = /\s+/g;
const REGEX_PARENTHESIS = /[()]/g;

/* 
  ****************** IMPORTANT NOTE ON {linkItems} ******************
  *******************************************************************
  We DO NOT allow spaces in the {linkItems} because they add 
  unexpected characters to the URL params.
  *******************************************************************
  *******************************************************************
*/
export default function AnchorLinks({
  isMobile = false,
  linkItems,
  alt,
  className,
  id
}) {

  const [drawer, setDrawer] = useState(false);
  const [initialScroll, setInitialScroll] = useState(false);


  const linkItemsFormatted = linkItems.map((item) => {
    const id = item.replace(REGEX_BLANK_SPACE, '-').replace(REGEX_PARENTHESIS, '');
    return { label: item, href: `#${id}`, id: id };
  });

  if (isMobile){
    useEffect(() => {
      if (initialScroll) {
        observeIntersection(linkItemsFormatted);
      }
      return () => {
        unobserveIntersaction();
      };
    }, [initialScroll]);

    useEffect(() => {

      const handleScroll = () => {
        if (window.scrollY <= WINDOW_SCROLL_TOP) {
          const mainText = document.querySelector('.main-text-mobile');
          mainText.innerHTML = MOBILE_SELECTOR_LABEL;
          unobserveIntersaction();
          setInitialScroll(false);
        }

        if (window.scrollY > INITIAL_SCROLL_TOP && !initialScroll) {
          setInitialScroll(true);
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
  }, []);
  }



  const drawerHandler = (e) => {
    onLinkClickOffset(e);
    setDrawer(!drawer);

  };

  return (
      <>
        <Root id={id}  className={className}>
          <MainDisplay onClick={drawerHandler}>
            <MainText className={isMobile? 'main-text-mobile': 'main-text-desktop'}>{MOBILE_SELECTOR_LABEL}</MainText>
            {
              !drawer ?
                <MainImageContainer src={DownCarat} alt={alt}/>
              :
                <MainImageContainer src={UpCarat} alt={alt} />
            }
            {
              linkItemsFormatted && linkItemsFormatted.map(({label, href}, i) => (
                  <DesktopLinkItem key={i} href={href}>
                    <DrawerImageContainer src={DownArrow} alt={alt} />
                    <DesktopLinkItemText>{label}</DesktopLinkItemText>
                  </DesktopLinkItem>
              ))
            }
          </MainDisplay>
          {
              drawer &&
              <DrawerContainer onClick={drawerHandler} >
                  {
                    linkItemsFormatted && linkItemsFormatted.map(({label, href}, i) => (
                      <DrawerItem key={i} href={href}>
                        <DrawerImageContainer src={DownArrow} alt={alt} />
                        <DrawerTextContainer>{label}</DrawerTextContainer>
                      </DrawerItem>
                    ))
                  }
              </DrawerContainer>
            }
        </Root>
        
      </>
  );
}

AnchorLinks.defaultProps = {
  alt: '',
};