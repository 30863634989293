import React from 'react';
import BulletList from '../BulletList';
import * as s from './styles';
import * as Text from '../../styles/shared/typography';
import StudyDesignPill from '../../assets/images/study_design_pills.png'
import styled from 'styled-components';

import EfficacyTable from '../../assets/images/efficacy-table.svg';
import EfficacyTableMobile from '../../assets/images/efficacy-table-mobile-v3.svg';
import { respondTo } from '../../styles/shared/breakpoints';

const DesktopTable = styled.img`
  display: none;
  width: 100%;
  ${respondTo.sm`
   display: block;
  `}
`;

const MobileTable = styled.img`
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;


  ${respondTo.sm`
   display: none;
  `}
`;

const StudyDesign = ({
  patientTitle,
  patientCardList,
  patientExclusionList,
  treatmentTitle,
  treatmentPill,
  treatmentPillSubtext,
  treatmentStudyList,
  treatmentStudyListPart2,
  assessmentTitle,
  assessmentCard,
  assessmentSubLists,
}) => {
  return (
    <s.StudyDesignWrapper>

      <DesktopTable alt='Study Design: WELIREGTM (belzutifan) Was Evaluated in LITESPARK-004, an Open-Label Clinical Trial' src={EfficacyTable}/>
      <MobileTable alt='Study Design: WELIREGTM (belzutifan) Was Evaluated in LITESPARK-004, an Open-Label Clinical Trial' src={EfficacyTableMobile}/>
      {/* <s.StudyColumn>

        <s.StudyCard>
          <s.StudyCardHeader>
            { patientTitle }
          </s.StudyCardHeader>
          <s.StudyCardBody>
            <s.StudyCardList listItems={ patientCardList } />
          </s.StudyCardBody>
        </s.StudyCard>

        <s.EfficacyBulletList
          listTitle={ patientExclusionList.title }
          listItems={ patientExclusionList.list }
        />

        <s.EfficacyBulletList
          listTitle={ treatmentStudyList.title }
          listItems={ treatmentStudyList.list }
        />

      </s.StudyColumn>
      <s.StudyColumn>

        <s.StudyCard>
          <s.StudyCardHeader>
            { treatmentTitle }
          </s.StudyCardHeader>
          <s.StudyCardBody>
            <s.StudyTreatmentGraphic>
            <Text.H6>{ treatmentPillSubtext }</Text.H6>

              <img src={StudyDesignPill}/>
              <span>Not actual size</span>
              {/* <span className="pill">{ treatmentPill }</span>
              <span className="pill">{ treatmentPill }</span>
              <span className="pill">{ treatmentPill }</span> 
            </s.StudyTreatmentGraphic>
          </s.StudyCardBody>
        </s.StudyCard>

        <s.EfficacyBulletList
          listTitle={treatmentStudyListPart2.title}
          listItems={treatmentStudyListPart2.list}
        />

      </s.StudyColumn>
      <s.StudyColumn>

        <s.StudyCard>
          <s.StudyCardHeader>
            { assessmentTitle }
          </s.StudyCardHeader>
          <s.StudyCardBody>
            <s.AssessmentH6>
              { assessmentCard }
            </s.AssessmentH6>
          </s.StudyCardBody>
        </s.StudyCard>

        {assessmentSubLists.map((listObj, i) => (
          <s.EfficacyBulletList
            key={i}
            listTitle={listObj.title}
            listItems={listObj.list}
          />
        ))}

      </s.StudyColumn> */}
    </s.StudyDesignWrapper>
  );
};


export default StudyDesign;